/*
import React from 'react';

const PdfDetails = ({ currentPdf, inputs, formType, onBack }) => {

  return (
    <div className='pdfDetails'>
      {inputs.length > 0 && (
        <div className="">
          <div className="header">
            <div className='goBack'>
              <h3 className="">{currentPdf} - Inputs ({formType})</h3>
              <button className="btn" onClick={onBack}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                Volver
                </button>
            </div>
            <div id="downloadContainer"></div>
          </div>
          <table className="">
            <thead className="">
              <tr>
                <th scope="col" className="">dataId</th>
                <th scope="col" className="">Value</th>
                <th scope="col" className="">type</th>
                <th scope="col" className="">Title</th>
                <th scope="col" className="">HubspotProp</th>
              </tr>
            </thead>
            <tbody className="">
              {inputs.map((input, i) => (
                <tr key={i}>
                  <td>{input.dataId}</td>
                  <td>{input.value}</td>
                  <td>{input.name}</td>
                  <td>{input.ariaLabel}</td>
                  <td>{input.hubspotProperty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PdfDetails;
*/




/*
import React from 'react';

const PdfDetails = ({ currentPdf, inputs, formType, inputValues, onBack }) => {
  return (
    <div className='pdfDetails'>
        <div className="header">
          <div className='goBack'>
            <h3 className="">{currentPdf} - Inputs ({formType})</h3>
            <button className="btn" onClick={onBack}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
              Volver
            </button>
          </div>
          <div id="downloadContainer"></div>
        </div>
      <table>
        <thead>
          <tr>
            <th>dataId</th>
            <th>Value</th>
            <th>type</th>
            <th>Title</th>
            <th>HubspotProp</th>
          </tr>
        </thead>
        <tbody>
          {inputs.map((input, i) => (
            <tr key={i}>
              <td>{input.dataId}</td>
              <td>
                <input
                  type="text"
                  value={inputValues[input.dataId] || input.value}
                  placeholder={input.ariaLabel}
                />
              </td>
              <td>{input.name}</td>
              <td>{input.ariaLabel}</td>
              <td>{input.hubspotProperty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PdfDetails;
*/



import React from 'react';

const PdfDetails = ({ currentPdf, inputs, formType, inputValues, onBack }) => {
  return (
    <div className='pdfDetails'>
        <div className="header">
          <div className='goBack'>
            <h3 className="">{currentPdf} - Inputs ({formType})</h3>
            <button className="btn" onClick={onBack}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
              Volver
            </button>
          </div>
          <div id="downloadContainer"></div>
        </div>
      <table className="">
        <thead className="">
          <tr>
            <th scope="col" className="">dataId</th>
            <th scope="col" className="">Value</th>
            <th scope="col" className="">type</th>
            <th scope="col" className="">Title</th>
            <th scope="col" className="">HubspotProp</th>
          </tr>
        </thead>
        <tbody className="">
          {inputs.map((input, i) => (
            <tr key={i}>
              <td>{input.dataId}</td>
              <td>
                {input.name === 'select' ? (
                  <select defaultValue={inputValues[input.dataId] || input.value}>
                    {input.options.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input 
                    type={input.name} 
                    defaultValue={inputValues[input.dataId] || input.value} 
                    placeholder={input.ariaLabel}
                  />
                )}
              </td>
              <td>{input.name}</td>
              <td>{input.ariaLabel}</td>
              <td>{input.hubspotProperty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PdfDetails;
