import React from 'react';
import PdfGalery from './components/PdfGalery';
import './App.css';

function App() {
  return (
    <div className="App">
      <main>
        <PdfGalery />
      </main>
    </div>
  );
}

export default App;
