/*
import React, { useState, useEffect } from 'react';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { Buffer } from 'buffer';
import PdfList from './PdfList';
import PdfDetails from './PdfDetails';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfGallery = () => {
  const [pdfList, setPdfList] = useState([]);
  const [error, setError] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [inputs, setInputs] = useState([]);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [formType, setFormType] = useState('');
  const [propiedadesTicket, setPropiedadesTicket] = useState({});
  
  const getTicketId = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('ticketId');
  };

  useEffect(() => {
    const ticketId = getTicketId();
    if (!ticketId) {
      setError('No ticket ID found in URL');
      return;
    }

    setTicketId(ticketId);

    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://cards.planbimmigration.com/server' : 'http://localhost:3100';

        const response = await fetch(`${baseUrl}/consultaPdfs?ticketId=${ticketId}`, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data)
        setPdfList(data.formulariosRequeridos['PRINCIPAL'] || []);
        setPropiedadesTicket(data.ticketInfo.properties);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const loadPdf = async (filename) => {
    const pdfUrl = `https://21669225.fs1.hubspotusercontent-na1.net/hubfs/21669225/ARCHIVOS_PRIVADOS/PDF_CANADA/TICKETS_SERVICIOS/${ticketId}/PRINCIPAL/${filename}.pdf`;
    console.log("pdfUrl", pdfUrl);
    setCurrentPdf(filename);
    console.log("Procesando PDF:", filename);

    try {
      const source = { url: pdfUrl };

      const pdfDocument = await pdfjs.getDocument({
        ...source,
        enableXfa: true,
      }).promise;

      let propsMaps = {};

      if (pdfDocument.allXfaHtml) {
        setFormType("xfa");
        const inputsList = getAllInputs(pdfDocument.allXfaHtml);
        setInputs(inputsList);

        let hubspotProperty = "";

        inputsList.forEach((input) => {
          const value = propiedadesTicket[input.dataId] || input.value || "";
          propsMaps[input.dataId] = {
            dataId: input.dataId,
            seccion: input.ariaLabel,
            hubspotProperty: hubspotProperty,
            type: input.name,
            value: value,
          };

          if (input.name === "select") {
            propsMaps[input.dataId].options = input.options;
          }
        });

        const schemaHubspotProps = await fetch(`./matchPropsForms/${filename}.json`)
          .then(resp => resp.json())
          .catch(e => console.log("error schema", e));

        Object.keys(schemaHubspotProps).forEach((prop) => {
          if (schemaHubspotProps[prop].type === "textarea" || schemaHubspotProps[prop].type === "select") {
            const value = propiedadesTicket[schemaHubspotProps[prop].hubspotProperty];
            pdfDocument.annotationStorage.setValue(prop, { value: value });
            value && console.log("change", prop, value);
          }
        });

      } else {
        setFormType("acro");
        const inputsList = getAllAcroInputs(await pdfDocument.getFieldObjects());
        setInputs(inputsList);

        let hubspotProperty = "";

        inputsList.forEach((input) => {
          const value = propiedadesTicket[input.dataId] || input.value || "";
          propsMaps[input.dataId] = {
            dataId: input.dataId,
            seccion: input.ariaLabel,
            hubspotProperty: hubspotProperty,
            type: input.name,
            value: value,
          };

          if (input.name === "select") {
            propsMaps[input.dataId].options = input.options;
          }
        });

        const schemaHubspotProps = await fetch(`./matchPropsForms/${filename}.json`)
          .then(resp => resp.json())
          .catch(e => console.log("error schema", e));

        Object.keys(schemaHubspotProps).forEach((prop) => {
          const value = propiedadesTicket[schemaHubspotProps[prop].hubspotProperty];
          pdfDocument.annotationStorage.setValue(prop, { value: value });
          value && console.log("change", prop, value);
        });
      }

      const data = pdfDocument.getData();
      const result = await pdfDocument.saveDocument(data);
      const buffer = Buffer.from(result);
      const pdfBlob = new Blob([buffer], { type: "application/pdf" });
      const PdfUrl = URL.createObjectURL(pdfBlob);

      const downloadContainer = document.createElement("div");
      downloadContainer.id = "downloadContainer"
      document.body.appendChild(downloadContainer);

      const downloadLink = document.createElement("a");
      downloadLink.href = PdfUrl;
      downloadLink.innerHTML = "Descargar";
      downloadLink.classList.add("download", "btn");
      downloadLink.download = filename + ".pdf";

      const jsonString = JSON.stringify(propsMaps);
      const jsonBlob = new Blob([jsonString], { type: "application/json" });
      const jsonUrl = URL.createObjectURL(jsonBlob);

      const mapLink = document.createElement("a");
      mapLink.href = jsonUrl;
      mapLink.classList.add("downloadMap", "btn");
      mapLink.innerHTML = "Descargar Mapa Props";
      mapLink.download = filename + ".json";

      document.getElementById("downloadContainer").appendChild(downloadLink)
      document.getElementById("downloadContainer").appendChild(mapLink)

    } catch (e) {
      console.error(e);
      setError("Could not load pdf, please check browser console");
    }
  };

  const getAllAcroInputs = (baseObj) => {
    const result = [];
    for (const key in baseObj) {
      for (const node of baseObj[key]) {
        if (!node.type) continue;
        result.push({
          name: node.type,
          dataId: node.id,
          ariaLabel: node.name,
          options: node.options ? node.options : [],
          value: node.value || "",
        });
      }
    }
    return result;
  };

  const getAllInputs = (node) => {
    const inputNodes = ["input", "textarea", "select"];
    const result = [];
    if (inputNodes.includes(node.name)) {
      const item = {
        name: node.name,
        dataId: node.attributes.dataId,
        value: node.attributes.value,
        textContent: node.attributes.textContent,
        ariaLabel: node.attributes["aria-label"],
        xfaOn: node.attributes.xfaOn,
      };
      if (node.name === "select") {
        let selected = false;
        item.options = [];
        if (Array.isArray(node.children)) {
          node.children.forEach((child) => {
            item.options.push({
              label: child.value,
              value: child.attributes.value,
            });
            selected = child.attributes.selected;
          });
        }
        item.selected = selected;
      }
      result.push(item);
    }

    if (Array.isArray(node.children)) {
      result.push(...node.children.flatMap((child) => getAllInputs(child)));
    }

    return result;
  };

  return (
    <div className='pdfContainer'>
      <div className="">
        <h2 className="">Aplicación de Visa - ID: {ticketId}</h2>
      </div>
      <h3>Listado de Formularios a rellenar:</h3>
      {!currentPdf ? (
        <PdfList pdfList={pdfList} ticketId={ticketId} onPdfSelect={loadPdf} />
      ) : (
        <PdfDetails 
          currentPdf={currentPdf}
          inputs={inputs}
          formType={formType}
          onBack={() => setCurrentPdf(null)}
        />
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default PdfGallery;
*/




/*
import React, { useState, useEffect } from 'react';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { Buffer } from 'buffer';
import PdfList from './PdfList';
import PdfDetails from './PdfDetails';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfGallery = () => {
  const [pdfList, setPdfList] = useState([]);
  const [error, setError] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [inputs, setInputs] = useState([]);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [formType, setFormType] = useState('');
  const [propiedadesTicket, setPropiedadesTicket] = useState({});
  const [inputValues, setInputValues] = useState({}); // Estado para almacenar los valores de los inputs

  const getTicketId = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('ticketId');
  };

  useEffect(() => {
    const ticketId = getTicketId();
    if (!ticketId) {
      setError('No ticket ID found in URL');
      return;
    }

    setTicketId(ticketId);

    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://cards.planbimmigration.com/server' : 'http://localhost:3100';

        const response = await fetch(`${baseUrl}/consultaPdfs?ticketId=${ticketId}`, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setPdfList(data.formulariosRequeridos['PRINCIPAL'] || []);
        setPropiedadesTicket(data.ticketInfo.properties);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const loadPdf = async (filename) => {
    const pdfUrl = `https://21669225.fs1.hubspotusercontent-na1.net/hubfs/21669225/ARCHIVOS_PRIVADOS/PDF_CANADA/TICKETS_SERVICIOS/${ticketId}/PRINCIPAL/${filename}.pdf`;
    console.log("pdfUrl", pdfUrl);
    setCurrentPdf(filename);
    console.log("Procesando PDF:", filename);

    try {
      const source = { url: pdfUrl };

      const pdfDocument = await pdfjs.getDocument({
        ...source,
        enableXfa: true,
      }).promise;

      let propsMaps = {};
      let values = {};

      if (pdfDocument.allXfaHtml) {
        setFormType("xfa");
        const inputsList = getAllInputs(pdfDocument.allXfaHtml);
        setInputs(inputsList);

        let hubspotProperty = "";

        inputsList.forEach((input) => {
          const value = propiedadesTicket[input.dataId] || input.value || "";
          propsMaps[input.dataId] = {
            dataId: input.dataId,
            seccion: input.ariaLabel,
            hubspotProperty: hubspotProperty,
            type: input.name,
            value: value,
          };

          values[input.dataId] = value; // Almacenar el valor para pasarlo a PdfDetails

          if (input.name === "select") {
            propsMaps[input.dataId].options = input.options;
          }
        });

        const schemaHubspotProps = await fetch(`./matchPropsForms/${filename}.json`)
          .then(resp => resp.json())
          .catch(e => console.log("error schema", e));

        Object.keys(schemaHubspotProps).forEach((prop) => {
          if (schemaHubspotProps[prop].type === "textarea" || schemaHubspotProps[prop].type === "select") {
            const value = propiedadesTicket[schemaHubspotProps[prop].hubspotProperty];
            pdfDocument.annotationStorage.setValue(prop, { value: value });
            value && console.log("change", prop, value);
            values[prop] = value; // Almacenar el valor para pasarlo a PdfDetails
          }
        });

      } else {
        setFormType("acro");
        const inputsList = getAllAcroInputs(await pdfDocument.getFieldObjects());
        setInputs(inputsList);

        let hubspotProperty = "";

        inputsList.forEach((input) => {
          const value = propiedadesTicket[input.dataId] || input.value || "";
          propsMaps[input.dataId] = {
            dataId: input.dataId,
            seccion: input.ariaLabel,
            hubspotProperty: hubspotProperty,
            type: input.name,
            value: value,
          };

          values[input.dataId] = value; // Almacenar el valor para pasarlo a PdfDetails

          if (input.name === "select") {
            propsMaps[input.dataId].options = input.options;
          }
        });

        const schemaHubspotProps = await fetch(`./matchPropsForms/${filename}.json`)
          .then(resp => resp.json())
          .catch(e => console.log("error schema", e));

        const annotationStorage = pdfDocument.annotationStorage;
        Object.keys(schemaHubspotProps).forEach((prop) => {
          const value = propiedadesTicket[schemaHubspotProps[prop].hubspotProperty];
          annotationStorage.setValue(prop, { value: value });
          value && console.log("change", prop, value);
          values[prop] = value; // Almacenar el valor para pasarlo a PdfDetails
        });
      }

      setInputValues(values); // Actualizar el estado con los valores de los inputs

      const result = await pdfDocument.saveDocument();
      const buffer = Buffer.from(result);
      const pdfBlob = new Blob([buffer], { type: "application/pdf" });
      const PdfUrl = URL.createObjectURL(pdfBlob);

      const downloadContainer = document.createElement("div");
      downloadContainer.id = "downloadContainer"
      document.body.appendChild(downloadContainer);

      const downloadLink = document.createElement("a");
      downloadLink.href = PdfUrl;
      downloadLink.innerHTML = "Descargar";
      downloadLink.classList.add("download", "btn");
      downloadLink.download = filename + ".pdf";

      const jsonString = JSON.stringify(propsMaps);
      const jsonBlob = new Blob([jsonString], { type: "application/json" });
      const jsonUrl = URL.createObjectURL(jsonBlob);

      const mapLink = document.createElement("a");
      mapLink.href = jsonUrl;
      mapLink.classList.add("downloadMap", "btn");
      mapLink.innerHTML = "Descargar Mapa Props";
      mapLink.download = filename + ".json";

      document.getElementById("downloadContainer").appendChild(downloadLink)
      document.getElementById("downloadContainer").appendChild(mapLink)

    } catch (e) {
      console.error(e);
      setError("Could not load pdf, please check browser console");
    }
  };

  const getAllAcroInputs = (baseObj) => {
    const result = [];
    for (const key in baseObj) {
      for (const node of baseObj[key]) {
        if (!node.type) continue;
        result.push({
          name: node.type,
          dataId: node.id,
          ariaLabel: node.name,
          options: node.options ? node.options : [],
          value: node.value || "",
        });
      }
    }
    return result;
  };

  const getAllInputs = (node) => {
    const inputNodes = ["input", "textarea", "select"];
    const result = [];
    if (inputNodes.includes(node.name)) {
      const item = {
        name: node.name,
        dataId: node.attributes.dataId,
        value: node.attributes.value,
        textContent: node.attributes.textContent,
        ariaLabel: node.attributes["aria-label"],
        xfaOn: node.attributes.xfaOn,
      };
      if (node.name === "select") {
        let selected = false;
        item.options = [];
        if (Array.isArray(node.children)) {
          node.children.forEach((child) => {
            item.options.push({
              label: child.value,
              value: child.attributes.value,
            });
            selected = child.attributes.selected;
          });
        }
        item.selected = selected;
      }
      result.push(item);
    }

    if (Array.isArray(node.children)) {
      result.push(...node.children.flatMap((child) => getAllInputs(child)));
    }

    return result;
  };

  return (
    <div className='pdfContainer'>
      <div className="">
        <h2 className="">Aplicación de Visa - ID: {ticketId}</h2>
      </div>
      <h3>Listado de Formularios a rellenar:</h3>
      {!currentPdf ? (
        <PdfList pdfList={pdfList} onPdfSelect={loadPdf} />
      ) : (
        <PdfDetails 
          currentPdf={currentPdf}
          inputs={inputs}
          formType={formType}
          inputValues={inputValues} // Pasar los valores de los inputs a PdfDetails
          onBack={() => setCurrentPdf(null)}
        />
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default PdfGallery;
*/






import React, { useState, useEffect } from 'react';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { Buffer } from 'buffer';
import PdfList from './PdfList';
import PdfDetails from './PdfDetails';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfGallery = () => {
  const [pdfList, setPdfList] = useState([]);
  const [error, setError] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [inputs, setInputs] = useState([]);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [formType, setFormType] = useState('');
  const [propiedadesTicket, setPropiedadesTicket] = useState({});
  const [inputValues, setInputValues] = useState({}); // Estado para almacenar los valores de los inputs

  const getTicketId = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('ticketId');
  };

  useEffect(() => {
    const ticketId = getTicketId();
    if (!ticketId) {
      setError('No ticket ID found in URL');
      return;
    }

    setTicketId(ticketId);

    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://cards.planbimmigration.com/server' : 'http://localhost:3100';

        const response = await fetch(`${baseUrl}/consultaPdfs?ticketId=${ticketId}`, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setPdfList(data.formulariosRequeridos['PRINCIPAL'] || []);
        setPropiedadesTicket(data.ticketInfo.properties);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const loadPdf = async (filename) => {
    const pdfUrl = `https://21669225.fs1.hubspotusercontent-na1.net/hubfs/21669225/ARCHIVOS_PRIVADOS/PDF_CANADA/TICKETS_SERVICIOS/${ticketId}/PRINCIPAL/${filename}.pdf`;
    console.log("pdfUrl", pdfUrl);
    setCurrentPdf(filename);
    console.log("Procesando PDF:", filename);

    try {
      const source = { url: pdfUrl };

      const pdfDocument = await pdfjs.getDocument({
        ...source,
        enableXfa: true,
      }).promise;

      let propsMaps = {};
      let values = {};

      if (pdfDocument.allXfaHtml) {
        setFormType("xfa");
        const inputsList = getAllInputs(pdfDocument.allXfaHtml);
        setInputs(inputsList);

        let hubspotProperty = "";

        inputsList.forEach((input) => {
          const value = propiedadesTicket[input.dataId] || input.value || "";
          propsMaps[input.dataId] = {
            dataId: input.dataId,
            seccion: input.ariaLabel,
            hubspotProperty: hubspotProperty,
            type: input.name,
            value: value,
          };

          values[input.dataId] = value; // Almacenar el valor para pasarlo a PdfDetails

          if (input.name === "select") {
            propsMaps[input.dataId].options = input.options;
          }
        });

        const schemaHubspotProps = await fetch(`./matchPropsForms/${filename}.json`)
          .then(resp => resp.json())
          .catch(e => console.log("error schema", e));

        Object.keys(schemaHubspotProps).forEach((prop) => {
          if (schemaHubspotProps[prop].type === "textarea" || schemaHubspotProps[prop].type === "select") {
            const value = propiedadesTicket[schemaHubspotProps[prop].hubspotProperty];
            pdfDocument.annotationStorage.setValue(prop, { value: value });
            value && console.log("change", prop, value);
            values[prop] = value; // Almacenar el valor para pasarlo a PdfDetails
          }
        });

      } else {
        setFormType("acro");
        const inputsList = getAllAcroInputs(await pdfDocument.getFieldObjects());
        setInputs(inputsList);

        let hubspotProperty = "";

        inputsList.forEach((input) => {
          const value = propiedadesTicket[input.dataId] || input.value || "";
          propsMaps[input.dataId] = {
            dataId: input.dataId,
            seccion: input.ariaLabel,
            hubspotProperty: hubspotProperty,
            type: input.name,
            value: value,
          };

          values[input.dataId] = value; // Almacenar el valor para pasarlo a PdfDetails

          if (input.name === "select") {
            propsMaps[input.dataId].options = input.options;
          }
        });

        const schemaHubspotProps = await fetch(`./matchPropsForms/${filename}.json`)
          .then(resp => resp.json())
          .catch(e => console.log("error schema", e));

        const annotationStorage = pdfDocument.annotationStorage;
        Object.keys(schemaHubspotProps).forEach((prop) => {
          const value = propiedadesTicket[schemaHubspotProps[prop].hubspotProperty];
          annotationStorage.setValue(prop, { value: value });
          value && console.log("change", prop, value);
          values[prop] = value; // Almacenar el valor para pasarlo a PdfDetails
        });
      }

      setInputValues(values); // Actualizar el estado con los valores de los inputs

      const result = await pdfDocument.saveDocument();
      const buffer = Buffer.from(result);
      const pdfBlob = new Blob([buffer], { type: "application/pdf" });
      const PdfUrl = URL.createObjectURL(pdfBlob);

      const downloadContainer = document.createElement("div");
      downloadContainer.id = "downloadContainer"
      document.body.appendChild(downloadContainer);

      const downloadLink = document.createElement("a");
      downloadLink.href = PdfUrl;
      downloadLink.innerHTML = "Descargar";
      downloadLink.classList.add("download", "btn");
      downloadLink.download = filename + ".pdf";

      const jsonString = JSON.stringify(propsMaps);
      const jsonBlob = new Blob([jsonString], { type: "application/json" });
      const jsonUrl = URL.createObjectURL(jsonBlob);

      const mapLink = document.createElement("a");
      mapLink.href = jsonUrl;
      mapLink.classList.add("downloadMap", "btn");
      mapLink.innerHTML = "Descargar Mapa Props";
      mapLink.download = filename + ".json";

      document.getElementById("downloadContainer").appendChild(downloadLink)
      document.getElementById("downloadContainer").appendChild(mapLink)

    } catch (e) {
      console.error(e);
      setError("Could not load pdf, please check browser console");
    }
  };

  const getAllAcroInputs = (baseObj) => {
    const result = [];
    for (const key in baseObj) {
      for (const node of baseObj[key]) {
        if (!node.type) continue;
        result.push({
          name: node.type,
          dataId: node.id,
          ariaLabel: node.name,
          options: node.options ? node.options : [],
          value: node.value || "",
        });
      }
    }
    return result;
  };

  const getAllInputs = (node) => {
    const inputNodes = ["input", "textarea", "select"];
    const result = [];
    if (inputNodes.includes(node.name)) {
      const item = {
        name: node.name,
        dataId: node.attributes.dataId,
        value: node.attributes.value,
        textContent: node.attributes.textContent,
        ariaLabel: node.attributes["aria-label"],
        xfaOn: node.attributes.xfaOn,
      };
      if (node.name === "select") {
        let selected = false;
        item.options = [];
        if (Array.isArray(node.children)) {
          node.children.forEach((child) => {
            item.options.push({
              label: child.value,
              value: child.attributes.value,
            });
            selected = child.attributes.selected;
          });
        }
        item.selected = selected;
      }
      result.push(item);
    }

    if (Array.isArray(node.children)) {
      result.push(...node.children.flatMap((child) => getAllInputs(child)));
    }

    return result;
  };

  return (
    <div className='pdfContainer'>
      <div className="">
        <h2 className="">Aplicación de Visa - ID: {ticketId}</h2>
      </div>
      <h3>Listado de Formularios a rellenar:</h3>
      {!currentPdf ? (
        <PdfList pdfList={pdfList} onPdfSelect={loadPdf} />
      ) : (
        <PdfDetails 
          currentPdf={currentPdf}
          inputs={inputs}
          formType={formType}
          inputValues={inputValues} // Pasar los valores de los inputs a PdfDetails
          onBack={() => setCurrentPdf(null)}
        />
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default PdfGallery;
